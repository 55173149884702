import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NotFoundSection from './_components/NotFoundSection';

const StyledContainer = styled(Box)(({ theme }) => ({
  background: `linear-gradient(0deg, #FFF, #242F8B)`,
  height: '100vh',
  width: '100vw',
}));

const NotFoundPage = () => {
  const navigate = useNavigate();
  const goToLoginPage = () => navigate('/login');

  return (
    <StyledContainer>
      <NotFoundSection goToLoginPage={goToLoginPage} />
    </StyledContainer>
  );
};

export default NotFoundPage;
