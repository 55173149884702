import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import SearchPage from '../../SearchPage/SearchPage';
import Layout from '../Layout';

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/search" />,
        },
        {
          path: '/search',
          element: <PrivateRoute component={SearchPage} />,
        },
      ],
    },
    {
      path: '/*',
      element: <NotFoundPage />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
