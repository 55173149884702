import moment from 'moment';
import { object, mixed, string } from 'yup';

const formSchema = object(
  {
    query: string(),
    period: mixed().oneOf(
      ['WEEK', 'MONTH', 'ALL', 'CUSTOM'],
      'Please select a time period'
    ),
    field: mixed().oneOf(
      ['TITLE', 'TITLE_AND_BODY'],
      'please select a search field'
    ),
    periodStartDate: mixed().when('period', {
      is: 'CUSTOM',
      then: (schema) =>
        schema
          .nullable()
          .test(
            'not-future',
            'Please select either today or a past date',
            (value) => value === null || !moment(value).isAfter(moment())
          )
          .test(
            'before end date',
            'Please select a date before the end date',
            (value, context) => {
              if (context.parent.periodEndDate === null) {
                return true;
              } else {
                return (
                  value === null ||
                  moment(value).isSameOrBefore(context.parent.periodEndDate)
                );
              }
            }
          ),
      otherwise: (schema) => schema,
    }),
    periodEndDate: mixed().when('period', {
      is: 'CUSTOM',
      then: (schema) =>
        schema
          .nullable()
          .test(
            'not-future',
            'Please select either today or a past date',
            (value) =>
              value === null || moment(value).isBefore(moment().add(1, 'days'))
          )
          .test(
            'after start date',
            'Please select date on or after the start date',
            (value, context) => {
              if (context.parent.periodStartDate === null) {
                return true;
              } else {
                return (
                  value === null ||
                  moment(value).isSameOrAfter(context.parent.periodStartDate)
                );
              }
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  },
  ['periodStartDate', 'periodEndDate']
);

export default formSchema;
