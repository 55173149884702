import { Box, Container } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import ErrorAlert from './_components/ErrorAlert';
import HitsSection from './_components/HitsSection';
import QuerySection from './_components/QuerySection';
import SearchResultsSection from './_components/SearchResultsSection';
import {
  findCategory,
  formatHitForm,
  formatPeriodDate,
} from './_components/utils';
import { CONSTANTS } from './constants';
import { exportDocuments, searchCategories, searchDocuments } from '../apis';
import Loading from '../Layouts/_components/Loading';

const SearchPage = () => {
  const { keycloak } = useKeycloak();

  const [search, setSearch] = useState({
    query: null,
    hits: null,
    results: null,
  });
  const [selectedHits, setSelectedHits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleGetHits = async (form) => {
    setLoading(true);
    const searchQuery = {
      ...form,
      page: 0,
      size: 100,
      sort: 'DESC',
    };

    if (searchQuery.period === 'ALL') {
      searchQuery.period = null;
    }

    if (searchQuery.period === 'CUSTOM') {
      searchQuery.period = null;
      searchQuery.periodStartDate = formatPeriodDate(
        searchQuery.periodStartDate
      );
      searchQuery.periodEndDate = formatPeriodDate(searchQuery.periodEndDate);
    }

    try {
      const { data: hitsRes } = await searchCategories(
        keycloak.tokenParsed.organizationId,
        searchQuery,
        keycloak.token
      );

      const hitsForm = formatHitForm(hitsRes.categories);

      setSearch({
        query: searchQuery,
        hits: hitsForm,
        results: null,
      });
      setError(null);
    } catch (error) {
      setError(CONSTANTS.ERROR_HIT_FETCH);
      setSearch({
        query: null,
        hits: null,
        results: null,
      });
    } finally {
      setLoading(false);
    }
  };

  const doSearch = async (searchQuery) => {
    setLoading(true);
    try {
      const { data: searchRes } = await searchDocuments(
        keycloak.tokenParsed.organizationId,
        searchQuery,
        keycloak.token
      );

      setSearch({
        query: searchQuery,
        hits: null,
        results: searchRes,
      });
      setError(null);
    } catch (errors) {
      setSearch({
        ...search,
        hits: null,
      });
      setError(CONSTANTS.ERROR_SEARCH_FETCH);
    } finally {
      setLoading(false);
    }
  };

  const handleGetSearchResults = async () => {
    const selectedCategories = selectedHits.map((category) => category[0]);
    const searchQuery = {
      ...search.query,
      categories: selectedCategories,
    };

    doSearch(searchQuery);
  };

  const handleSort = (sort) => doSearch({ ...search.query, sort: sort });
  const handlePageChange = (page) => doSearch({ ...search.query, page: page });
  const handlePerPageChange = (perPage) =>
    doSearch({ ...search.query, size: perPage, page: 0 });
  const handleExport = async (format, includeBody, docIds) => {
    const body = {
      format: format,
      includeBody: includeBody,
      documentIds: docIds,
    };

    try {
      const exportRes = await exportDocuments(
        keycloak.tokenParsed.organizationId,
        body,
        keycloak.token
      );

      const filename = exportRes.headers['content-disposition']
        .split('filename=')[1]
        .replaceAll('"', '');

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(exportRes.data);
      link.download = filename;
      link.click();
      setError(null);
    } catch (error) {
      setError(CONSTANTS.ERROR_EXPORT_DATA);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!search.hits) return;

    let count = [];

    const countChecked = (list) => {
      const listItems = Object.values(list);

      listItems.forEach((item) => {
        if (item.checked) {
          count.push([item.name, item.count]);
        } else {
          const children = Object.keys(item.categories);
          children.length > 0 && countChecked(item.categories);
        }
      });
    };

    countChecked(search.hits);
    setSelectedHits(count);
  }, [search.hits]);

  const handleCheckCategory = (name, value) => {
    const categoryList = { ...search.hits };
    const splitName = name.split('/');

    // manipulates the category list and updates its values
    findCategory(categoryList, splitName, value);
    setSearch({
      ...search,
      hits: categoryList,
    });
  };

  const handleCheckAll = (checked) => {
    const categoryList = Object.keys(search.hits);
    categoryList.map((category) => handleCheckCategory(category, checked));
  };

  return (
    <Container>
      <QuerySection onSearch={handleGetHits} />
      {error && (
        <Box mt={2}>
          <ErrorAlert msg={error} />
        </Box>
      )}
      {loading && <Loading fullscreen />}
      {search.hits && (
        <HitsSection
          hits={search.hits}
          selectedHits={selectedHits}
          onCheckCategory={handleCheckCategory}
          onCheckAll={handleCheckAll}
          onGetDetails={handleGetSearchResults}
        />
      )}
      {search.results && (
        <SearchResultsSection
          results={search.results}
          onSort={handleSort}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          onExport={handleExport}
        />
      )}
    </Container>
  );
};

export default SearchPage;
