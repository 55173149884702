export const formatFormValues = (form) => {
  const formArray = Object.entries(form);

  const selectedValues = formArray.reduce((acc, curr) => {
    if (curr[1]) acc.push(curr[0]);
    return acc;
  }, []);

  return selectedValues.sort();
};

export const filterHits = (hits) => {
  const hitsArray = hits.map((hit) => hit.split('/'));

  const filteredHits = hitsArray.reduce((acc, curr) => {
    if (!acc[0]) {
      acc.push(curr);
      return acc;
    }

    const compare = acc[acc.length - 1];
    for (let i = 0; i < curr.length; i++) {
      if (compare[i]) {
        if (compare[i] !== curr[i]) acc.push(curr);
      }
    }

    return acc;
  }, []);

  const joinedHits = filteredHits.map((hit) => hit.join('/'));

  return joinedHits;
};

export const countHits = (selectedHits, hitList) => {
  const formattedHits = formatFormValues(selectedHits);
  const filteredHits = filterHits(formattedHits);

  let count = 0;

  for (let i = 0; i < filteredHits.length; i++) {
    const splitHits = filteredHits[i].split('/');
    let propertyName = hitList;

    splitHits.forEach((key) => {
      if (key === splitHits[splitHits.length - 1]) {
        propertyName = propertyName[key];
      } else {
        propertyName = propertyName[key].categories;
      }
    });
    count += propertyName.count;
  }

  return count;
};

export const formatHitForm = (hits) => {
  const keys = Object.keys(hits);
  keys.forEach((key) => {
    hits[key].checked = false;
    if (Object.keys(hits[key].categories).length > 0) {
      formatHitForm(hits[key].categories);
    }
  });

  return hits;
};

export const findCategory = (list, keys, checkVal) => {
  const newList = { ...list };

  // set parents to false if child is false
  if (!checkVal) {
    newList[keys[0]].checked = checkVal;
  }

  if (keys.length === 1) {
    newList[keys[0]].checked = checkVal;

    // propogates check through to children
    const children = Object.keys(newList[keys[0]].categories);
    if (children.length > 0) {
      children.forEach((child) => {
        findCategory(newList[keys[0]].categories, [child], checkVal);
      });
    }
    return;
  }

  return findCategory(newList[keys[0]].categories, keys.slice(1), checkVal);
};

export const formatPeriodDate = (date) => {
  if (!!date) {
    return date.format('YYYY-MM-DD');
  } else {
    return null;
  }
};
