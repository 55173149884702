import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const VERSION = 'v1';

const instance = axios.create({
  baseURL: `${API_BASE_URL}/${VERSION}`,
  timeout: 5000,
});

export const searchCategories = (orgId, body, token) =>
  instance.post(`/organizations/${orgId}/categories/search`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const searchDocuments = (orgId, body, token) =>
  instance.post(`/organizations/${orgId}/documents/search`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });

export const fetchQuota = (orgId, token) =>
  instance.get(`/organizations/${orgId}/quotas/latest`, {
    headers: { Authorization: `Bearer ${token}` },
  });

export const exportDocuments = (orgId, body, token) =>
  instance.post(`/organizations/${orgId}/documents/export`, body, {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob',
  });
