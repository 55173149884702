import { Backdrop, Box, CircularProgress } from '@mui/material';

const Loading = ({ fullscreen }) => {
  return fullscreen ? (
    <Backdrop
      open={true}
      aria-label="loading"
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box
      minHeight={100}
      display="flex"
      justifyContent="center"
      alignItems="center"
      aria-label="loading"
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
