import { Box, Button, ButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';
import ExportSettings from './ExportSettings';
import { CONSTANTS } from '../constants';

const SearchResultSettings = ({
  restricted,
  selected,
  sort,
  onSortChange,
  onExport,
}) => {
  return (
    <>
      <Box display="flex" mt={2} justifyContent="space-between">
        <ExportSettings onExport={onExport} selected={selected} />
        <Box display="flex" alignItems="center">
          <ButtonGroup size="small" disabled={restricted} disableElevation>
            <Button
              sx={{ py: 0.5 }}
              variant={sort === 'DESC' ? 'contained' : 'outlined'}
              onClick={() => onSortChange('DESC')}
            >
              {CONSTANTS.SORT_DESC}
            </Button>
            <Button
              sx={{ py: 0.5 }}
              variant={sort === 'ASC' ? 'contained' : 'outlined'}
              onClick={() => onSortChange('ASC')}
            >
              {CONSTANTS.SORT_ASC}
            </Button>
            <Button
              sx={{ py: 0.5 }}
              variant={sort === null ? 'contained' : 'outlined'}
              onClick={() => onSortChange(null)}
            >
              {CONSTANTS.SORT_RELEVANT}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

SearchResultSettings.propTypes = {
  restricted: PropTypes.bool,
  selected: PropTypes.array.isRequired,
  sort: PropTypes.string,
  onSortChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default SearchResultSettings;
