import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';

const PrivateRoute = ({ component: Component }) => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) keycloak.login();
    // eslint-disable-next-line
  }, []);

  return keycloak.authenticated && <Component />;
};

export default PrivateRoute;
