import styled from '@emotion/styled';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useQuota } from '../../contexts/QuotaContext';
import { CONSTANTS } from '../constants';

const StyledButton = styled(Button)({
  marginRight: 2,
});

const QuotaButton = () => {
  const { quota, updateQuota } = useQuota();
  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    await updateQuota();
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <StyledButton variant="outlined" color="inherit" onClick={handleOpen}>
        {CONSTANTS.DISPLAY_QUOTA}
      </StyledButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{CONSTANTS.TITLE}</DialogTitle>
        <DialogContent>
          {!quota.search || !quota.export ? (
            <Alert severity="error">{CONSTANTS.FETCH_ERROR}</Alert>
          ) : (
            <>
              <Typography gutterBottom>
                {CONSTANTS.SEARCH_QUOTA(
                  `${quota.search[0]} / ${
                    quota.search[1] < 0 ? CONSTANTS.UNLIMITED : quota.search[1]
                  }`
                )}
              </Typography>
              <Typography>
                {CONSTANTS.EXPORT_QUOTA(
                  `${quota.export[0]} / ${
                    quota.export[1] < 0 ? CONSTANTS.UNLIMITED : quota.export[1]
                  }`
                )}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{CONSTANTS.CLOSE}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuotaButton;
