import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';

const HitListMap = ({ parent, hit, onCheckCategory }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  const children = Object.keys(hit[1].categories);

  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <FormControlLabel
              label={`${hit[1].title}: ${hit[1].count}`}
              control={
                <Checkbox
                  checked={hit[1].checked}
                  disableRipple
                  onChange={(e) => {
                    onCheckCategory(hit[1].name, e.target.checked);
                  }}
                />
              }
            />
          }
        />

        {children.length > 0 && (
          <IconButton onClick={handleToggle}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </ListItem>
      {children.length > 0 && (
        <Collapse in={open}>
          <List sx={{ pl: 4 }} disablePadding dense>
            {/* Recursively maps through the nested hits */}
            {Object.entries(hit[1].categories).map((child) => (
              <HitListMap
                key={child[1].name}
                parent={parent ? `${parent}/${hit[1].title}` : hit[1].title}
                hit={child}
                onCheckCategory={onCheckCategory}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default HitListMap;
