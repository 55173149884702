import { useKeycloak } from '@react-keycloak/web';
import { createContext, useContext, useState } from 'react';
import { fetchQuota } from '../apis';

export const QuotaContext = createContext();

export const useQuota = () => {
  const context = useContext(QuotaContext);
  if (!context) {
    throw new Error('useQuota must be added within QuotaContextProvider');
  }

  return context;
};

const QuotaProvider = ({ children }) => {
  const { keycloak } = useKeycloak();

  const [searchQuota, setSearchQuota] = useState(null);
  const [exportQuota, setExportQuota] = useState(null);

  const updateQuota = async () => {
    try {
      const { data: quotaRes } = await fetchQuota(
        keycloak.tokenParsed.organizationId,
        keycloak.token
      );

      const search = quotaRes.find((q) => q.type === 'SEARCH');
      const exp = quotaRes.find((q) => q.type === 'EXPORT');

      setSearchQuota([search.amountUsed, search.amountProvided]);
      setExportQuota([exp.amountUsed, exp.amountProvided]);
    } catch {
      setSearchQuota(null);
      setExportQuota(null);
    }
  };

  return (
    <QuotaContext.Provider
      value={{
        quota: { search: searchQuota, export: exportQuota },
        updateQuota,
      }}
    >
      {children}
    </QuotaContext.Provider>
  );
};

export default QuotaProvider;
