import { Alert } from '@mui/material';
import PropTypes from 'prop-types';

const ErrorAlert = ({ msg }) => {
  return <Alert severity="error">{msg}</Alert>;
};

ErrorAlert.propTypes = {
  msg: PropTypes.string,
};

export default ErrorAlert;
