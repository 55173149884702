import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CONSTANTS } from '../constants';

const ExportSettings = ({ selected, onExport }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [exportContents, setExportContents] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleExcelExport = async () => {
    setLoading(true);
    setError(false);
    try {
      await onExport('XLS', exportContents);
      handleClose();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCsvExport = async () => {
    setLoading(true);
    setError(false);
    try {
      await onExport('CSV', exportContents);
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const valuesAreSelected = selected.length > 0;
    setDisabled(!valuesAreSelected);
    // eslint-disable-next-line
  }, [selected]);

  const numSelected = () => selected.filter((val) => val).length;

  return (
    <Box>
      <Button
        variant="contained"
        sx={{ mr: 2 }}
        onClick={handleOpen}
        disabled={disabled}
      >
        {CONSTANTS.EXPORT_TO_FILE}
      </Button>
      <FormControlLabel
        value={exportContents}
        onChange={(e) => setExportContents(!exportContents)}
        control={<Checkbox />}
        label={CONSTANTS.EXPORT_CONTENTS}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{CONSTANTS.EXPORT_TITLE}</DialogTitle>
        <DialogContent>
          {error && (
            <Alert sx={{ mb: 1 }} severity="error">
              {CONSTANTS.EXPORT_ERROR}
            </Alert>
          )}
          <Typography>{CONSTANTS.EXPORT_DETAILS(numSelected())}</Typography>
          <Typography mb={2}>{CONSTANTS.EXPORT_CONSUMES_QUOTA}</Typography>
          <Typography>{CONSTANTS.EXPORT_BODY}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{CONSTANTS.CANCEL}</Button>
          <LoadingButton
            variant="contained"
            onClick={handleExcelExport}
            disabled={loading}
            loading={loading}
          >
            {CONSTANTS.EXCEL_DOWNLOAD}
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleCsvExport}
            disabled={loading}
            loading={loading}
          >
            {CONSTANTS.CSV_DOWNLOAD}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExportSettings;
