import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import formSchema from './formSchema';
import { CONSTANTS } from '../constants';

const StyledTextField = styled(TextField)({
  [`& fieldset`]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const QuerySection = ({ onSearch }) => {
  const [searchPeriod, setSearchPeriod] = useState('WEEK');

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      query: '',
    },
  });

  const handleDateChange = (event) => {
    const period = event.target.value;
    switch (period) {
      case 'WEEK':
      case 'MONTH':
        setValue('periodStartDate', '');
        setValue('periodEndDate', '');
        break;
      case 'ALL':
        setValue('periodStartDate', '');
        setValue('periodEndDate', '');
        break;
      case 'CUSTOM':
        setValue('periodStartDate', moment().subtract(7, 'days'));
        setValue('periodEndDate', moment());
        break;
      default:
    }
    setSearchPeriod(period);
  };

  const handleSearch = (form) => onSearch(form);

  return (
    <section>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Box display="flex" mt={2}>
          <Controller
            name="query"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <StyledTextField
                {...field}
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon sx={{ mr: 1 }} />,
                }}
              />
            )}
          />
          <Button
            color="primary"
            size="large"
            variant="contained"
            type="submit"
            disabled={watch('query').trim() === ''}
            sx={{
              width: 120,
              borderStartStartRadius: 0,
              borderEndStartRadius: 0,
              boxShadow: 'none',
            }}
          >
            {CONSTANTS.SEARCH}
          </Button>
        </Box>

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Box display="flex" alignItems="center">
            <Typography sx={{ fontWeight: 'bold' }} mr={0.5}>
              {CONSTANTS.TIME_PERIOD}
            </Typography>
            <Tooltip title={CONSTANTS.SEARCH_TIP}>
              <InfoIcon fontSize="small" sx={{ mr: 2 }} />
            </Tooltip>
            <Controller
              name="period"
              control={control}
              defaultValue="WEEK"
              render={({ field }) => (
                <FormControl {...field}>
                  <RadioGroup
                    value={searchPeriod}
                    onChange={handleDateChange}
                    row
                  >
                    <FormControlLabel
                      value="WEEK"
                      label={CONSTANTS.WEEK}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="MONTH"
                      label={CONSTANTS.MONTH}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="ALL"
                      label={CONSTANTS.ALL_TIME}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="CUSTOM"
                      label={CONSTANTS.CUSTOM}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
          <Box>
            <Controller
              name="field"
              control={control}
              defaultValue="TITLE_AND_BODY"
              render={({ field }) => (
                <FormControl {...field}>
                  <RadioGroup defaultValue="TITLE_AND_BODY" row>
                    <FormControlLabel
                      value="TITLE"
                      label={CONSTANTS.TITLE_ONLY}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="TITLE_AND_BODY"
                      label={CONSTANTS.TITLE_BODY}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
        </Box>
        {searchPeriod === 'CUSTOM' && (
          <Box display="flex" mt={1} gap={2}>
            <Controller
              name="periodStartDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  maxDate={moment()}
                  label={CONSTANTS.START_DATE}
                  selected={field.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: 200 }}
                      size="small"
                      error={!!errors?.periodStartDate}
                      helperText={errors?.periodStartDate?.message}
                    />
                  )}
                />
              )}
            />

            <Controller
              name="periodEndDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  maxDate={moment()}
                  label={CONSTANTS.END_DATE}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ width: 200 }}
                      size="small"
                      error={!!errors?.periodEndDate}
                      helperText={errors?.periodEndDate?.message}
                    />
                  )}
                />
              )}
            />
          </Box>
        )}
      </form>
    </section>
  );
};

QuerySection.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default QuerySection;
