import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useKeycloak } from '@react-keycloak/web';
import { Outlet } from 'react-router';
import QuotaButton from './_components/QuotaButton';
import { CONSTANTS } from './constants';

const StyledTitle = styled(Typography)({
  flexGrow: 1,
});

const StyledButton = styled(Button)({
  marginRight: 2,
});

const Layout = () => {
  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    keycloak.logout({ redirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URI });
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <StyledTitle variant="h6">
            {'Wiseworks-Search'.toUpperCase()}
          </StyledTitle>
          {keycloak?.authenticated && (
            <Box display="flex" gap={2}>
              <QuotaButton />
              <StyledButton
                variant="outlined"
                color="inherit"
                onClick={handleLogout}
                endIcon={<LogoutIcon />}
              >
                {CONSTANTS.LOGOUT}
              </StyledButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );
};

export default Layout;
