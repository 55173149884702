import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import ReactDOM from 'react-dom/client';
import QuotaProvider from './contexts/QuotaContext';
import './index.css';
import keycloak from './keycloak';
import AppRouter from './Layouts/_components/AppRouter';
import Loading from './Layouts/_components/Loading';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    LoadingComponent={<Loading fullscreen />}
  >
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <QuotaProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AppRouter />
          </LocalizationProvider>
        </QuotaProvider>
      </ThemeProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
