export const CONSTANTS = {
  CLOSE: '閉じる',
  DISPLAY_QUOTA: 'ご利用状況',
  EXPORT_QUOTA: (quota) => `ファイル出力: ${quota} 件`,
  FETCH_ERROR: 'クォータの取得中にエラーが発生しました',
  LOGOUT: 'ログアウト',
  SEARCH_QUOTA: (quota) => `検索詳細表示: ${quota} 件`,
  TITLE: 'お客様のご利用状況は以下の通りです。',
  UNLIMITED: '無制限',
};
