import { Checkbox, Link, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { memo } from 'react';

const SearchResultTableRow = memo(({ doc, checked, onCheck }) => {
  const titleStyle = {
    width: 400,
    maxWidth: 400,
    borderStyle: 'border-box',
  };

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          disableRipple
          checked={checked}
          onChange={(_) => onCheck(doc.id)}
        />
      </TableCell>
      <TableCell align="left" sx={titleStyle}>
        <Link
          href={decodeURIComponent(doc.url)}
          target="_blank"
          underline="hover"
        >
          {doc.title}
        </Link>
      </TableCell>
      <TableCell align="center">
        {moment(doc.date).format('YYYY/MM/DD')}
      </TableCell>
      <TableCell align="center">{doc.mediaTitle}</TableCell>
      <TableCell align="center">{doc.mediaKind}</TableCell>
      <TableCell align="center">{doc.mediaCategory}</TableCell>
    </TableRow>
  );
});

export default SearchResultTableRow;
