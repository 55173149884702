export const CONSTANTS = {
  ALL_TIME: '全期間',
  CANCEL: 'キャンセル',
  CATEGORY: 'カテゴリ',
  CUSTOM: 'カスタム',
  CSV_DOWNLOAD: '.csvのダウンロード',
  DATE: '日付',
  DISPLAY: '表示する',
  DOCS_ON_PAGE: (start, end, total) => `${start} - ${end}件目 (全${total}件)`,
  END_DATE: 'まで',
  ERROR_HIT_FETCH: '検索カテゴリの取得中にエラーが発生しました',
  ERROR_SEARCH_FETCH: '検索結果の取得中にエラーが発生しました',
  ERROR_EXPORT_DATA: 'ドキュメントのエクスポート中にエラーが発生しました',
  EXPORT_BODY:
    '結果は、csv または Excel ファイルとしてダウンロードできます。 以下の適切なファイル タイプのボタンをクリックしてください。',
  EXPORT_CONSUMES_QUOTA: '出力すると、ファイル出力件数クォータを消費します。',
  EXPORT_CONTENTS: 'コンテンツデータも出力する',
  EXPORT_DETAILS: (quota) =>
    `記事詳細をファイルに出力しますか？（${quota}件選択されています。）`,
  EXCEL_DOWNLOAD: 'Excelのダウンロード',
  EXPORT_ERROR: 'ドキュメントのエクスポート中にエラーが発生しました',
  EXPORT_RESTRICTED:
    '割り当てが不十分なため、ファイル出力が制限されています。 追加の割り当てを購入してください。',
  EXPORT_TITLE: 'ファイル出力の確認',
  EXPORT_TO_FILE: 'ファイル出力',
  FOUND_HITS: (hits) => `${hits} ヒットしました`,
  HIT_RESULTS: `検索結果:`,
  MEDIA_KIND: 'メディア種別',
  MONTH: '1ヶ月',
  PER_PAGE: 'ページあたりの行数: ',
  QUOTA_USE_CONFIRM: 'クォータ消費確認',
  SEARCH: '検索',
  SEARCH_RESTRICTED:
    '割り当てが不十分なため、検索結果が制限されています。 完全な結果を表示するには、追加の割り当てを購入してください。',
  SEARCH_TIP: '検索対象範囲は過去１年',
  SELECT_ALL: '全選択',
  DESELECT_ALL: '全解除',
  SHOW_DETAILS: (quota) =>
    `記事詳細を表示しますか？（${quota}件選択されています。）`,
  SITE: 'サイト',
  SORT_ASC: '古い順',
  SORT_DESC: '新しい順',
  SORT_RELEVANT: '関連度順',
  START_DATE: 'から',
  TITLE: 'タイトル',
  TITLE_ONLY: 'タイトルのみ',
  TITLE_BODY: 'タイトルと本文',
  TIME_PERIOD: '期間 ',
  VIEW_CONSUMES_QUOTA: '表示すると、詳細表示件数クォータを消費します。',
  WEEK: '1週間',
};
