import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';

import SearchResultTableRow from './SearchResultTableRow';
import TablePagination from './TablePagination';
import { CONSTANTS } from '../constants';

const SearchResultTable = ({
  results,
  selected,
  onPerPageChange,
  onPageChange,
  onCheck,
  onCheckAll,
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);

  const handlePageChange = async (_, page) => {
    setPage(page);
    await onPageChange(page - 1);
  };

  const handlePerPageChange = async (e) => {
    setPerPage(e.target.value);
    setPage(1);
    await onPerPageChange(e.target.value);
  };

  const handleCheckAll = (_, id) => {
    onCheckAll(id);
  };

  const headers = [
    CONSTANTS.TITLE,
    CONSTANTS.DATE,
    CONSTANTS.SITE,
    CONSTANTS.MEDIA_KIND,
    CONSTANTS.CATEGORY,
  ];
  const someChecked = selected.length > 0 && selected.length < perPage;
  const allChecked =
    results.count < perPage
      ? selected.length === results.count
      : selected.length === perPage;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                <TablePagination
                  results={results}
                  page={page}
                  size={perPage}
                  onPageChange={handlePageChange}
                  onSizeChange={handlePerPageChange}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={someChecked && !allChecked}
                  checked={allChecked}
                  disableRipple
                  onChange={handleCheckAll}
                />
              </TableCell>
              {headers.map((column, i) => (
                <TableCell key={i} align={!i ? 'left' : 'center'}>
                  <b>{column}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.documents.map((doc) => (
              <SearchResultTableRow
                key={doc.id}
                doc={doc}
                checked={selected.indexOf(doc.id) > -1}
                onCheck={onCheck}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <TablePagination
                  results={results}
                  page={page}
                  size={perPage}
                  onPageChange={handlePageChange}
                  onSizeChange={handlePerPageChange}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SearchResultTable;
