import { createTheme } from '@mui/material';
import { jaJP } from '@mui/material/locale';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#242F8B',
      },
    },
  },
  jaJP
);

export default theme;
