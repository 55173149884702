import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { CONSTANTS } from '../constants';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

const StyledError = styled(Typography)(({ theme }) => ({
  fontSize: '20rem',
  lineHeight: '1',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    fontSize: '10rem',
    marginTop: '5%',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '30%',
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '5em',
  fontWeight: 'bold',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    color: 'black',
    fontSize: '2em',
  },
}));

const NotFoundSection = ({ goToLoginPage }) => {
  return (
    <StyledBox>
      <StyledError fontSize="20rem" lineHeight="1" color="white">
        404
      </StyledError>
      <StyledTitle>{CONSTANTS.PAGE_NOT_FOUND}</StyledTitle>
      <Typography fontWeight="300" align="center">
        {CONSTANTS.CHECK_URL}
      </Typography>
      <Button
        variant="contained"
        sx={{ marginTop: '2em', marginBottom: '2em' }}
        onClick={goToLoginPage}
      >
        {CONSTANTS.TO_LOGIN_PAGE}
      </Button>
    </StyledBox>
  );
};

NotFoundSection.propTypes = {
  goToLoginPage: PropTypes.func.isRequired,
};

export default NotFoundSection;
