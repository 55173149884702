import { Alert, Box, Typography } from '@mui/material';
import { useState } from 'react';
import SearchResultSettings from './SearchResultSettings';
import SearchResultTable from './SearchResultTable';
import { useQuota } from '../../contexts/QuotaContext';
import { CONSTANTS } from '../constants';

const SearchResultsSection = ({
  results,
  onSort,
  onPageChange,
  onPerPageChange,
  onExport,
}) => {
  const { quota, updateQuota } = useQuota();
  const [selected, setSelected] = useState([]);

  const handleSortChange = (value) => {
    setSelected([]);
    onSort(value);
  };
  const handlePerPageChange = (value) => onPerPageChange(value);
  const handlePageChange = (value) => {
    setSelected([]);
    onPageChange(value);
  };

  const handleCheck = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter((val) => val !== id);
    }

    setSelected(newSelected);
  };

  const handleCheckAll = async (checked) => {
    if (checked) {
      const allIds = results.documents.map((doc) => doc.id);
      setSelected(allIds);
    } else {
      setSelected([]);
    }
  };

  const handleExport = async (format, includeBody) => {
    onExport(format, includeBody, selected);
    updateQuota();
  };

  return (
    <section>
      {results?.restricted && (
        <Alert severity="info" sx={{ my: 1 }}>
          <Typography>{CONSTANTS.SEARCH_RESTRICTED}</Typography>
        </Alert>
      )}
      {quota?.export?.[1] - quota?.export?.[0] === 0 && (
        <Alert severity="info" sx={{ my: 1 }}>
          <Typography>{CONSTANTS.EXPORT_RESTRICTED}</Typography>
        </Alert>
      )}
      <SearchResultSettings
        restricted={results?.restricted}
        sort={results.sort}
        selected={selected}
        onCheckAll={handleCheckAll}
        onSortChange={handleSortChange}
        onPerPageChange={handlePerPageChange}
        onExport={handleExport}
      />
      <Box my={2}>
        <SearchResultTable
          results={results}
          selected={selected}
          onPageChange={handlePageChange}
          onPerPageChange={handlePerPageChange}
          onCheck={handleCheck}
          onCheckAll={handleCheckAll}
        />
      </Box>
    </section>
  );
};

export default SearchResultsSection;
