import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from '@mui/material';
import { CONSTANTS } from '../constants';

const TablePagination = ({
  results,
  page,
  size,
  onPageChange,
  onSizeChange,
}) => {
  const pageLengths = [100, 300, 500];

  const pageStart = results.page * results.size + 1;
  const pageEnd =
    results.count < results.size * (results.page + 1)
      ? results.count
      : results.size * (results.page + 1);

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flex={1} alignItems="center">
        <Typography variant="body2">{CONSTANTS.PER_PAGE}</Typography>
        <FormControl sx={{ marginTop: '5px' }}>
          <Select
            size="small"
            variant="standard"
            value={size}
            onChange={onSizeChange}
            sx={{ ml: 2 }}
            disableUnderline
          >
            {pageLengths.map((pageLength) => (
              <MenuItem value={pageLength} key={pageLength}>
                {pageLength}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box flex={1}>
        <Typography align="center">
          {CONSTANTS.DOCS_ON_PAGE(pageStart, pageEnd, results.count)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end" minWidth={342}>
        {Math.ceil(results.count / results.size) > 1 && (
          <Pagination
            shape="rounded"
            variant="outlined"
            count={Math.ceil(results.count / results.size)}
            page={page}
            onChange={onPageChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default TablePagination;
