import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { CONSTANTS } from '../constants';

const SearchButton = ({ selectedHits, onConfirm }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const totalQuota = selectedHits.reduce((acc, curr) => acc + curr[1], 0);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        disabled={selectedHits.length === 0}
      >
        {CONSTANTS.DISPLAY}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{CONSTANTS.QUOTA_USE_CONFIRM}</DialogTitle>
        <DialogContent>
          {CONSTANTS.SHOW_DETAILS(totalQuota)}
          {CONSTANTS.VIEW_CONSUMES_QUOTA}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{CONSTANTS.CANCEL}</Button>
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            disabled={loading}
            loading={loading}
          >
            {CONSTANTS.DISPLAY}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchButton;
