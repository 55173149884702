import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { CONSTANTS } from '../constants';

const SelectAllButton = ({ allSelected, onClick }) => {
  // if everything selected, returns false to deselect all and vice-versa
  const handleClick = () => onClick(!allSelected);

  return allSelected ? (
    <Button variant="contained" onClick={handleClick}>
      {CONSTANTS.DESELECT_ALL}
    </Button>
  ) : (
    <Button variant="contained" onClick={handleClick}>
      {CONSTANTS.SELECT_ALL}
    </Button>
  );
};

SelectAllButton.propTypes = {
  allSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SelectAllButton;
