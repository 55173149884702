import { Box, Grid, List, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import HitListMap from './HitListMap';
import SearchButton from './SearchButton';
import SelectAllButton from './SelectAllButton';
import { CONSTANTS } from '../constants';

const HitsSection = ({
  hits,
  selectedHits,
  onCheckCategory,
  onCheckAll,
  onGetDetails,
}) => {
  const handleCheckCategory = (name, checked) => onCheckCategory(name, checked);

  const numOfHits = Object.values(hits).reduce(
    (prev, curr) => prev + curr.count,
    0
  );

  const numOfSelectedHits = selectedHits.reduce(
    (prev, curr) => prev + curr[1],
    0
  );

  return (
    <section>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        mb={1}
      >
        <Box display="flex">
          <Typography>{CONSTANTS.HIT_RESULTS}</Typography> &nbsp;
          <Typography fontWeight="bold">
            {CONSTANTS.FOUND_HITS(numOfHits || 0)}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <SelectAllButton
            allSelected={numOfSelectedHits === numOfHits}
            onClick={onCheckAll}
          />
          <SearchButton selectedHits={selectedHits} onConfirm={onGetDetails} />
        </Box>
      </Box>

      <Grid container spacing={2} marginBottom={2}>
        {Object.entries(hits).map((parent) => (
          <Grid key={parent[1].name} item xs={12} sm={6} md={4}>
            <List component={Paper} disablePadding dense>
              <HitListMap hit={parent} onCheckCategory={handleCheckCategory} />
            </List>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

HitsSection.propTypes = {
  hits: PropTypes.object,
  selectedHits: PropTypes.array,
  onGetDetails: PropTypes.func.isRequired,
};

export default HitsSection;
